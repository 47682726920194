import { FixedServiceRequestTypes } from "../../core/constants/constants";
import { IStyledCheckboxes } from "../../core/types/types";
import ExclamationMark from "../ExclamationMark/ExclamationMark";

function StyledCheckboxes({
  serviceRequest,
  label,
  serviceList,
  onChange,
  checkboxErrorMessage,
}: IStyledCheckboxes) {
  return (
    <div className="flex flex-col items-start w-full mb-4 bg-white rounded px-4 py-3 border border-gray-300 shadow-sm">
      <label htmlFor={label} className="text-base text-gray-500 pb-4">
        {label}
      </label>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-1 w-full">
        {Object.entries(serviceList).map(
          ([itemKey, itemValue]: [string, string], index: number) => {
            return (
              <div
                className="flex flex-row items-center w-full select-none"
                key={index}
              >
                <label
                  htmlFor={itemKey}
                  className="whitespace-nowrap text-base text-gray-800 cursor-pointer"
                >
                  <input
                    id={itemKey}
                    type="checkbox"
                    name={itemKey}
                    value={itemKey}
                    title={label}
                    checked={
                      serviceRequest.service?.includes(itemKey) ? true : false
                    }
                    className="mr-2 cursor-pointer"
                    onChange={onChange}
                  />
                  {itemValue}
                </label>
              </div>
            );
          }
        )}
      </div>
      {serviceRequest.serviceRequest ===
        FixedServiceRequestTypes.DAMAGED_BIN && (
        <div className="mt-4 p-2 w-full border border-gray-500 rounded flex items-center">
          <p className="text-base text-gray-800 flex-grow mr-4">
            Place your bin on the kerbside and leave it there for 2-5 business
            days for it to be collected and replaced
          </p>
          <div className="mr-2 flex-shrink-0">
            <ExclamationMark color="#6b7280" />
          </div>
        </div>
      )}
      {checkboxErrorMessage && (
        <div className="mt-4 p-2 w-full border border-red-500 rounded flex items-center">
          <p className="text-base text-red-500 flex-grow mr-4">
            {checkboxErrorMessage}
          </p>
          <div className="mr-2 flex-shrink-0">
            <ExclamationMark />
          </div>
        </div>
      )}
    </div>
  );
}

export default StyledCheckboxes;
